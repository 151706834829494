import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AppFaq from "../components/faq-section"
import {faqCreatorsData} from "../data/creators-data"
import {faqFollowersData} from "../data/followers-data"
import styled from "styled-components"
import {ContentHeader, ContentWrapper} from "../components/styles/global-styles"

const FaqsPage = () => (
  <Layout>
    <Seo title="FAQ - Perguntas Frequentes" />
    <FaqContainer>
      <ContentWrapper isLarge="true">
        <ContentHeader>FAQ - Perguntas Frequentes</ContentHeader>
        <h2>Seguidores</h2>
        <AppFaq dataToShow={faqFollowersData} />
        <h2>Criadores</h2>
        <AppFaq dataToShow={faqCreatorsData} />
      </ContentWrapper>
    </FaqContainer>
  </Layout>
)

export default FaqsPage

const FaqContainer = styled.div`
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 60px calc((100vw - 1300px) / 2);
  color: #fff;

  h2 {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`