export const faqFollowersData = {
  title: "",
  rows: [
    {
      title: "Como comprar um pack?",
      content: `Ao acessar a página de um pack, haverá um botão escrito "Liberar Acesso". Clique nele e entre na sua conta ou crie uma nova conta com um e-mail e senha de preferência. A página será redirecionada para a página de pagamento da Stripe, na qual poderá pagar com cartão ou boleto. É só preencher os campos, clicar em pagar e pronto!`,
    },
    {
      title: "Como vou acessar o conteúdo adquirido?",
      content: `Após o pagamento ser confirmado, o conteúdo adquirido estará na sua página inicial. É só acessar https://app.privetly.com e, após entrar com sua conta, você poderá acessar o pack comprado.`,
    },
    {
      title: "Quais são as formas de pagamento?",
      content: `É possível pagar com cartão de crédito e débito Visa, Mastercard e Maestro, além das carteiras digitais Apple Pay e Google Pay. Pagamento por Boleto Bancário também está disponível. Todo o processo é seguro e feito dentro do ecossistema da Stripe, uma das maiores processadoras de pagamentos do mercado.`,
    },
    {
      title: "É seguro comprar pela privetly?",
      content: `Com certeza! O pagamento é processado pela Stripe, uma dos maiores gateways de pagamentos do mercado, contando com o que há de mais moderno em segurança de pagamentos. Além disso, a entrega do seu conteúdo por email é garantida por nós.`,
    },
    {
      title: "Tenho garantia quanto ao conteúdo adquirido?",
      content: `O conteúdo dos packs é controlado por quem os criou. Na página do pack é exibida uma pré-visualização borrada para dar uma ideia do que você está adquirindo. Contudo, após o pagamento ser confirmado, você tem 7 dias para abrir uma disputa pelo ajuda@privetly.com. É só explicar pra gente o motivo e, se de fato quem criou tiver agido de má fé, você receberá o estorno.`,
    },
    {
      title: "Quanto tempo leva para ter acesso ao conteúdo?",
      content: `Depende da forma de pagamento escolhida. Se for cartão, dentro de poucos minutos você deve ter acesso. Se for boleto, depende do tempo que você levar para concluir o pagamento e este ser processado.`,
    },
    {
      title: "Posso cancelar a compra ou pedir estorno?",
      content: `Após a confirmação do pagamento, você tem 7 dias para abrir uma disputa pelo ajuda@privetly.com. É só explicar pra gente o motivo e, se de fato quem criou tiver agido de má fé, você receberá o estorno.`,
    },
  ],
};